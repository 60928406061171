p,
h5,
h3,
a,
button {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.react-router-link {
  color: inherit;
  text-decoration: none; /* Optional: Remove underline */
}

a {
  text-decoration: none;
}

.card {
  background-color: #f5f5f5;
}

p {
  font-size: 18px !important;
}
@media screen and (max-width: 700px) {
  p {
    font-size: 16px !important;
  }
}

.header h5 {
  color: black;
}

p {
  color: gray;
}

.photos {
  margin: auto;
  padding: 70px;
}
.photos .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.photos .body {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.photos .body .card {
  border: 0 !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.photos .body .card .image {
  width: auto;
  height: 300px;
}
.photos .body .card .image img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
}
.photos .body .card .text {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.photos .button {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  gap: 10px;
}
.photos .button button {
  padding: 10px 25px;
  border: 0;
  border-radius: 10px;
  background-color: rgb(18, 102, 211);
  color: white;
}
.photos .button .fty-btn {
  border: 1px solid rgb(224, 77, 102) !important;
  background: transparent !important;
  color: rgb(18, 102, 211) !important;
}

@media screen and (max-width: 700px) and (min-width: 300px) and (max-width: 600px) {
  .photos {
    padding: 10px;
  }
  .photos .body {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 700px) and (min-width: 300px) and (max-width: 600px) and (min-width: 450px) and (max-width: 600px) {
  .photos .body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 700px) and (min-width: 300px) and (max-width: 600px) {
  .photos .body .card {
    width: 100% !important;
    height: 300px;
  }
}
@media screen and (max-width: 700px) and (min-width: 300px) and (max-width: 600px) and (min-width: 450px) and (max-width: 600px) {
  .photos .body .card {
    width: auto;
  }
}
@media screen and (max-width: 700px) and (min-width: 300px) and (max-width: 600px) {
  .photos .button {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    gap: 10px;
  }
  .photos .button button {
    padding: 10px 25px;
    border: 0;
    border-radius: 10px;
    background-color: rgb(18, 102, 211);
    color: white;
  }
}/*# sourceMappingURL=style.css.map */