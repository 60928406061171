$primary-width: 90%;
$prmary-font: 'Plus Jakarta Sans', sans-serif;
$primary-color: rgb(18, 102, 211);
$secondary-color: rgb(224, 77, 102);
p,
h5,
h3,
a,
button {
  font-family: $prmary-font;
}
.react-router-link {
  color: inherit;
  text-decoration: none; /* Optional: Remove underline */
}
a {
  text-decoration: none;
}
.card {
  background-color: #f5f5f5;
}
p{
  font-size: 18px !important;
@media screen and (max-width:700px) {
  font-size: 16px !important;
}
}
.header {
  h5 {
    color: black;
  }
}

p {
  color: gray;
}

.photos {
  margin: auto;
  padding: 70px;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .body {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    gap: 10px;
    .card {
      border: 0 !important;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      .image {
        width: auto;
        height: 300px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
      }
      .text {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .button {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    gap: 10px;

    button {
      padding: 10px 25px;
      border: 0;
      border-radius: 10px;
      background-color: $primary-color;
      color: white;
    }

    .fty-btn {
      border: 1px solid $secondary-color !important;
      background: transparent !important;
      color: $primary-color !important;
    }
  }
}

@media (max-width: 700px) {
@media screen and (min-width: 300px) and (max-width: 600px) {


  .photos {
padding: 10px;
    .body {
      display: flex;
      flex-direction: column;

      @media (min-width: "450px") and (max-width: "600px") {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      .card {
        width: 100% !important;
        height: 300px;
        @media (min-width: "450px") and (max-width: "600px") {
          width: auto;
        }
      }
    }

    .button {
      display: flex;
      justify-content: center;
      padding-top: 10px;
      gap: 10px;

      button {
        padding: 10px 25px;
        border: 0;
        border-radius: 10px;
        background-color: $primary-color;
        color: white;
      }
    }
  }
}
}
